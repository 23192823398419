import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getTalleres(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${data.queryParams.codigo}/global/${data.queryParams.id}/taller`, { params: data.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTaller(ctx, { codigo, id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/${codigo}/global/${id}/taller`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTaller(ctx, { codigo, id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-cultural-deportivo/${codigo}/global/${id}/taller/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTaller(ctx, { codigo, id, tallerId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-cultural-deportivo/${codigo}/global/${id}/taller/${tallerId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTallerGlobal(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${params.codigo}/global/${params.id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
  },

}
