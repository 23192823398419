<template>
  <b-container class="bg-white bordered">
    <b-modal
      id="modal_nuevo_taller"
      :title="tituloModal"
      ok-only
      :hide-footer="true"
    >
      <form
        ref="form"
        @submit.prevent="guardar_actualizar"
      >
        <b-form-group
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <label
            for=""
            class="mt-1"
          >Nombre</label>
          <b-form-input
            id="name-input"
            v-model="taller.nombre"
            required
          />
          <label
            for=""
            class="mt-1"
          >
            {{ codigo === 'OCADE-ALT-COMP' ? 'Tutor/Entrenador' : 'Docente' }}
          </label>
          <v-select
            v-model="profesorSelect"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="profesores"
          />
          <b-form-group
            label="Semestre:"
            label-for="semestre_convocatoria"
            class="mt-1"
          >
            <b-form-select
              id="semestre_convocatoria"
              v-model="taller.semestre_id"
              required
              :options="optionsSemestres"
            />

          </b-form-group>
          <b-row>
            <b-col
              cols="6"
            >
              <label
                for=""
                class="mt-1"
              >Fecha Inicio</label>
              <b-form-input
                v-model="taller.f_inicio"
                type="date"
                required
              />
            </b-col>
            <b-col
              cols="6"
            >
              <label
                for=""
                class="mt-1"
              >Fecha Fin</label>
              <b-form-input
                v-model="taller.f_fin"
                type="date"
                required
              />
            </b-col>
          </b-row>
          <br>
          <b-row v-if="actionTaller">
            <b-col
              cols="3"
            >
              <label
                for=""
                class="mt-1"
              >Estado: </label>
            </b-col>
            <b-col
              cols="9"
            >
              <b-form-radio-group
                v-model="taller.estado"
                name="radiobtn"
                class="pt-1"
              >
                <b-form-radio
                  :value="1"
                >
                  ACTIVO
                </b-form-radio>
                <b-form-radio
                  :value="0"
                >
                  INACTIVO
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
        </b-form-group>
        <br>
        <b-button
          variant="outline-primary"
          size="sm"
          class="float-right"
          type="submit"
        >
          {{ tituloBoton }}
          <feather-icon icon="SaveIcon" />
        </b-button>
      </form>

    </b-modal>
  </b-container>
</template>

<script>
import {
  BContainer, BFormSelect, BButton, BFormGroup, BFormInput, BRow, BCol, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'

import store from '@/store'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BContainer,
    BFormSelect,
    BRow,
    BCol,
    BFormRadio,
    BFormRadioGroup,
    FeatherIcon,
    vSelect,
  },
  props: {
    codigo: {
      type: String,
      default: '#',
    },
  },
  data() {
    return {
      global_id: this.$route.params.id,
      optionsSemestres: [],
      taller: {
        nombre: '',
        profesor_id: '',
        f_inicio: '',
        f_fin: '',
        taller_global_id: '',
        estado: true,
      },
      tituloModal: '',
      tituloBoton: '',
      guardarEditar: true,
      profesores: [],
      profesorSelect: null,
      actionTaller: false,
    }
  },
  created() {
    this.$parent.$on('modalNuevoTaller', this.modalNuevoTaller)
    this.$parent.$on('modalEditarTaller', this.modalEditarTaller)
    this.$http.get('/servicio-cultural-deportivo/profesor?sin_paginar=true').then(res => {
      this.profesores = res.data
    })
  },
  mounted() {
    this.$http.get('/servicio-social/beca/show-semestres').then(res => {
      this.optionsSemestres = res.data.map(value => ({
        value: value.id, text: value.nombre,
      }))
    })
  },
  methods: {
    modalNuevoTaller() {
      if (this.codigo === 'OCADE-ALT-COMP') {
        this.tituloModal = 'Nuevo Programa Deportivo'
        this.tituloBoton = 'Registrar Programa'
      } else {
        this.tituloModal = 'Nuevo Taller'
        this.tituloBoton = 'Registrar Taller'
      }
      this.guardarEditar = true
      // this.$refs.tallerNuevo.taller = {}
      this.actionTaller = false
      this.taller = {}
      this.$bvModal.show('modal_nuevo_taller')
    },
    modalEditarTaller(item) {
      this.actionTaller = true
      this.taller.nombre = item.nombre
      this.taller.f_inicio = item.f_inicio
      this.taller.f_fin = item.f_fin
      this.taller.semestre_id = item.semestre_id
      this.taller.id = item.id
      this.taller.estado = item.estado
      delete this.taller.taller_global_id

      this.profesorSelect = this.profesores.find(val => val.id === parseInt(item.profesor_id, 10))

      if (this.codigo === 'OCADE-ALT-COMP') {
        this.tituloModal = 'Editar Programa Deportivo'
        this.tituloBoton = 'Actualizar Programa'
      } else {
        this.tituloModal = 'Editar Taller'
        this.tituloBoton = 'Actualizar Taller'
      }
      this.guardarEditar = false
      this.$bvModal.show('modal_nuevo_taller')
    },
    guardar_actualizar() {
      this.taller.profesor_id = this.profesorSelect.id
      const data = {
        codigo: this.codigo,
        id: this.global_id,
        data: this.taller,
      }
      if (this.guardarEditar) { // true = guardar
        store.dispatch('talleres-store-module/addTaller', data).then(() => {
          this.$swal({
            position: 'top-end',
            icon: 'success',
            title: this.codigo === 'OCADE-ALT-COMP' ? '¡Programa registrado!' : '¡Taller registrado!',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$emit('actualizarTablaTalleres')
          this.$bvModal.hide('modal_nuevo_taller')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error!',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
      } else {
        store.dispatch('talleres-store-module/updateTaller', data).then(() => {
          this.$swal({
            position: 'top-end',
            icon: 'success',
            title: this.codigo === 'OCADE-ALT-COMP' ? '¡Programa actualizado!' : '¡Taller actualizado!',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$emit('actualizarTablaTalleres')
          this.$bvModal.hide('modal_nuevo_taller')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error!',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
