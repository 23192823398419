<template>
  <div>
    <ModalInformeTaller />
    <ModalTallerNuevo
      :codigo="codigo"
      @actualizarTablaTalleres="actualizarTablaTalleres"
    />
    <b-card
      fluid
      class="pb-2"
    >
      <b-row class="border-bottom">
        <b-col
          xl="7"
          lg="7"
          md="6"
          sm="12"
        >
          <h4 class="text-primary pb-1 mt-1">
            <feather-icon
              icon="LifeBuoyIcon"
              size="20"
              class="mr-1"
            />
            <span v-if="codigo === 'OCADE-DEPORTIVO'">TALLER GLOBAL DEPORTIVO:</span>
            <span v-else-if="codigo === 'OCADE-CULTURAL'">TALLER GLOBAL CULTURAL:</span>
            <span v-else-if="codigo === 'OCADE-ALT-COMP'">PROGRAMA DEPORTIVO:</span>
            <b-badge
              variant="light-primary"
              class="px-3 ml-1"
            >
              {{ tallerGlobal_nombre }}
            </b-badge>
          </h4>
        </b-col>
        <b-col
          xl="5"
          lg="5"
          md="6"
          sm="12"
        >
          <b-button
            size="sm"
            variant="primary"
            class="mt-1 px-3 float-right"
            @click="nuevoTaller"
          >
            <feather-icon icon="PlusCircleIcon" />
            Nuevo {{ codigo === 'OCADE-ALT-COMP' ? 'programa' : 'taller' }} de {{ tallerGlobal_nombre }}
          </b-button>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col
          cols="7"
          class=""
        >
          <label>Mostrar</label>
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="per-page-selector d-inline-block mx-50"
            style="width:auto"
          />
          <label>registros</label>
        </b-col>
        <b-col
          cols="5"
          class="mb-1"
        >
          <b-form-group
            label=""
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar..."
                size="sm"
              />

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  variant="success"
                  size="sm"
                  @click="filter = ''"
                >
                  Limpiar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group></b-col>
      </b-row>
      <b-table
        ref="refTablaTalleres"
        :items="myProvider"
        :fields="fields"
        :no-provider-filtering="true"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        head-variant="light"
        class="position-relative"
        show-empty
        small
        hover
        responsive
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>
        <template #cell(index)="row">
          {{ from + row.index }}
        </template>
        <template #cell(nombre)="row">
          <span class="text-primary"><strong>{{ row.item.nombre }}</strong></span>
        </template>
        <template #cell(acciones)="row">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="editarTaller(row.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item

              :to="{ name: nameRuta, params: { globalId: $route.params.id, id: row.item.id} }"
            >
              <feather-icon icon="UsersIcon" />
              <span class="align-middle ml-50">Estudiantes</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: nameRuta2, params: { globalId: $route.params.id, id: row.item.id} }"
            >
              <feather-icon icon="ListIcon" />
              <span class="align-middle ml-50">
                {{ codigo === 'OCADE-ALT-COMP' ? 'Entranamientos' : 'Actividades' }}
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="eliminarCursoTaller(row.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="generarInformeTaller(row.item)"
            >
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Generar Informe</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(estado)="row">
          <b-badge
            pill
            :variant="`light-${resolveTallerStatusVariant(row.item.estado)}`"
          >
            {{ row.item.estado === 1 ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BDropdown, BSpinner, BDropdownItem, BBadge, BFormSelect, BButton, BFormGroup, BFormInput, BRow, BCol, BInputGroup, BPagination, BTable, BInputGroupAppend,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import talleresStoreModule from '@/views/ocade/talleres-globales/talleres/talleresStoreModule'
import ModalTallerNuevo from '@/views/ocade/talleres-globales/talleres/ModalTallerNuevo.vue'
import ModalInformeTaller from '@/views/ocade/talleres-globales/talleres/ModalInformeTaller.vue'

export default {
  components: {
    ModalTallerNuevo,
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BFormSelect,
    BPagination,
    BTable,
    BInputGroupAppend,
    BBadge,
    BSpinner,
    ModalInformeTaller,
  },
  props: {
    codigo: {
      type: String,
      default: '#',
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      cursoTallerUpdate: {},
      tallerGlobal_nombre: '',
      sortDirection: 'desc',
      actionTaller: false,
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [5, 10, 50, 100, 500],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],
      btnActivo: 0,

      nameRuta: '',
      nameRuta2: '',
    }
  },

  mounted() {
    switch (this.codigo) {
      case 'OCADE-DEPORTIVO':
        this.nameRuta = 'ocade-servicio-deportivo-taller-estudiantes'
        this.nameRuta2 = 'ocade-servicio-deportivo-taller-actividades'
        break
      case 'OCADE-CULTURAL':
        this.nameRuta = 'ocade-servicio-cultural-taller-estudiantes'
        this.nameRuta2 = 'ocade-servicio-cultural-taller-actividades'
        break
      case 'OCADE-ALT-COMP':
        this.nameRuta = 'ocade-prodac-programa-estudiantes'
        this.nameRuta2 = 'ocade-prodac-programa-actividades'
        break
      default:
        break
    }
    store.dispatch('talleres-store-module/fetchTallerGlobal', { codigo: this.codigo, id: this.id }).then(response => {
      this.tallerGlobal_nombre = response.nombre
    })
  },
  methods: {
    actualizarTablaTalleres() {
      this.filter = null
      this.$refs.refTablaTalleres.refresh()
    },
    async myProvider(ctx) {
      // eslint-disable-next-line no-param-reassign
      ctx.codigo = this.codigo; ctx.id = this.id
      const promise = store.dispatch('talleres-store-module/getTalleres', { queryParams: ctx })
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data

        const items = data
        this.totalRows = total || 0
        this.from = from || 0
        this.to = to || 0
        return items || []
      })
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      // this.actualizar_to_from()
    },
    nuevoTaller() {
      this.$emit('modalNuevoTaller')
    },
    editarTaller(item) {
      this.$emit('modalEditarTaller', item)
    },
    eliminarCursoTaller(value) {
      if (!value.estado) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Advertencia!',
            text: this.codigo === 'OCADE-ALT-COMP' ? '¡El programa no puede ser eliminado porque se encuentra inactivo.!' : '¡El taller no puede ser eliminado porque se encuentra inactivo.!',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        return
      }

      this.$swal({
        title: `Esta seguro de eliminar el registro ${value.nombre}`,
        text: 'Esta acción es irreversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = {
            codigo: this.codigo,
            id: this.$route.params.id,
            tallerId: value.id,
          }
          store.dispatch('talleres-store-module/deleteTaller', data).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Realizado!',
              text: 'Taller eliminado.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.actualizarTablaTalleres()
          }).catch(() => {
          })
        }
      })
    },
    generarInformeTaller(item) {
      this.$emit('abrirModalGenerarInformeTaller', { codigo: this.codigo, id: item.id })
    },

  },
  setup() {
    const TALLERES_APP_STORE_MODULE_NAME = 'talleres-store-module'
    // Register module
    if (!store.hasModule(TALLERES_APP_STORE_MODULE_NAME)) store.registerModule(TALLERES_APP_STORE_MODULE_NAME, talleresStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TALLERES_APP_STORE_MODULE_NAME)) store.unregisterModule(TALLERES_APP_STORE_MODULE_NAME)
    })
    const resolveTallerStatusVariant = estado => {
      if (estado === 1) return 'success'
      if (estado === 0) return 'danger'
      return 'primary'
    }
    const fields = [
      { key: 'index', label: 'N°', sortable: true },
      { key: 'nombre', label: 'Nombre', sortable: true },
      { key: 'semestre.nombre', label: 'Semestre', sortable: true },
      { key: 'profesor.name', label: 'Profesor', sortable: true },
      {
        key: 'f_inicio', label: 'FECHA Inicio', sortDirection: 'desc', sortable: true,
      },
      { key: 'f_fin', label: 'FECHA Fin', sortable: true },
      { key: 'estado', label: 'Estado', sortable: true },
      { key: 'acciones', label: 'acciones' },
    ]
    return {
      fields,
      resolveTallerStatusVariant,
    }
  },
}
</script>
