<template>
  <div>
    <b-modal
      id="modal_generar_informe_taller"
      ok-only
      size="md"
      header-bg-variant="info"
      hide-footer
    >
      <template
        #modal-header
      >
        <div class="text-white">
          <strong class="float-left">
            Generar informe del taller
          </strong></div>

      </template>
      <!-- BODY -->
      <b-row>

        <b-col
          md="12"
          sm="12"
        >
          <b-form-group
            label=""
          >
            <b-form-checkbox
              v-model="conFirma"
              unchecked-value="0"
              value="1"
              switch
              class="ml-3 mr-2 p-0"
            >
              <span
                class="p-0"
                style="font-size:14px"
              >Con firma</span>
            </b-form-checkbox>

          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between mt-2 border-top pt-1">
        <b-button
          type="button"
          variant="outline-danger"
          size="sm"
          @click="$bvModal.hide('modal_generar_informe_taller')"
        >
          <feather-icon
            icon="XIcon"
            size="14"
          />
          Cancelar
        </b-button>
        <b-button
          :variant="`outline-success`"
          class="mr-2"
          @click="descargarInformeTaller"
        >
          Descargar informe
          <feather-icon
            icon="CopyIcon"
            size="14"
          />
        </b-button>
      </div>
      <b-overlay
        :show="show"
        no-wrap
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BButton, BModal, BFormGroup, BRow, BCol, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormGroup,
    BOverlay,
  },

  data() {
    return {
      conFirma: 0,
      show: false,
      codigo: '',
      idTaller: 0,
    }
  },

  created() {
    this.$parent.$on('abrirModalGenerarInformeTaller', this.abrirModalGenerarInformeTaller)
  },
  methods: {
    abrirModalGenerarInformeTaller({ codigo, id }) {
      this.codigo = codigo
      this.idTaller = id
      this.$bvModal.show('modal_generar_informe_taller')
    },

    descargarInformeTaller() {
      this.show = true
      this.$http.get(`/servicio-cultural-deportivo/${this.codigo}/taller/${this.idTaller}/reporte`,
        {
          params: { firma: this.conFirma },
          responseType: 'blob', // important
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', `InformeTaller_${this.idTaller}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.show = false
          this.$bvModal.hide('modal_generar_informe_taller')
        })
    },
  },
}
</script>
